/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

/** @jsx jsx */

import PropTypes from 'prop-types'
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  IconButton,
  useColorMode,
  useThemeUI,
  jsx
} from 'theme-ui'
import Facebook from '~/assets/images/icons/share_facebook.svg'
import Link from '~/assets/images/icons/share_link.svg'
import Twitter from '~/assets/images/icons/share_twitter.svg'
import { useLocation } from '@reach/router'
import TextBlock from '~/components/Generic/TextBlock'
import { Fragment, useEffect, useState } from 'react'
import Spacer from '~/components/Generic/Spacer'
import ImageWithSubtitle from '~/components/Generic/ImageWithSubtitle'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import RelatedArticles from '../Related'
import { GenericSectionDivider } from '~/components/Generic/SectionDivider'
import ImgLoader from '~/components/Generic/ImgLoader'

const ArticlePage = ({ article }) => {
  const { title, authorName, content, image, tags, publishDate, theme, video } =
    article
  const { href } = useLocation()

  const [copied, setCopied] = useState(false)

  const [colorMode, setColorMode] = useColorMode(theme)

  useEffect(() => {
    setColorMode(theme ?? 'Blog-Pink')
  }, [])

  const {
    theme: { rawColors }
  } = useThemeUI()

  return (
    <Container
      variant="fullWidth"
      sx={{ backgroundColor: rawColors.secondary, overflowX: 'hidden' }}
    >
      <Text
        sx={{
          position: 'fixed',
          pointerEvents: 'none',
          bottom: '60px',
          left: '50%',
          backgroundColor: rawColors.background,
          color: rawColors.secondary,
          border: '2px solid',
          borderRadius: '10px',
          boxShadow: '0 7px 20px rgba(0,0,0,0.2)',
          fontWeight: 'bold',
          padding: '10px 15px',
          zIndex: 2,
          opacity: copied ? 1 : 0,
          transform: copied
            ? 'translateX(-50%) translateY(0px) scale(1)'
            : 'translateX(-50%) translateY(10px) scale(0.95)',
          transition:
            'opacity 0.2s ease, transform 0.2s cubic-bezier(.45,.97,.48,1.4)'
        }}
      >
        Copied to clipboard!
      </Text>
      <Box
        sx={{
          position: 'relative',
          width: '100%'
        }}
      >
        <Box sx={{ backgroundColor: rawColors.background }}>
          <Flex
            sx={{
              p: ['50px 30px', '50px 150px 50px 119px'],
              width: '100%',
              maxWidth: '1440px',
              m: '0 auto',
              alignItems: 'center',
              gap: ['30px', '97px'],
              flexDirection: ['column-reverse', 'row']
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                width: ['100%', '51%']
              }}
            >
              {(tags || publishDate) && (
                <Flex
                  sx={{
                    justifyContent: 'space-between',
                    width: '76%',
                    flexWrap: 'wrap',
                    gap: ['15px', '0 15px'],
                    flexDirection: ['column', 'row'],
                    fontSize: 1,
                    fontWeight: 700
                  }}
                >
                  {tags && <span>{tags[0]}</span>}
                  {publishDate && (
                    <span sx={{ fontSize: [0, 1] }}>
                      {publishDate.split('|')[1]}
                    </span>
                  )}
                </Flex>
              )}
              <Heading
                as="h2"
                variant="h2"
                sx={{
                  marginTop: ['15px', null, '30px'],
                  marginBottom: '15px',
                  fontSize: 6,
                  textTransform: 'uppercase'
                }}
              >
                {title}
              </Heading>
              {authorName && (
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 400,
                    padding: 0,
                    textAlign: 'left'
                  }}
                >
                  {authorName}
                </Text>
              )}
              <Flex
                sx={{
                  gap: '9px',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '700',
                  mt: '36px',
                  button: {
                    display: 'flex',
                    alignItems: 'center'
                  },
                  span: {
                    mr: '3px'
                  },
                  rect: {
                    stroke: rawColors.text
                  },
                  path: {
                    fill: rawColors.text
                  }
                }}
              >
                <span>SHARE:</span>
                <FacebookShareButton url={href}>
                  <Facebook />
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <Twitter />
                </TwitterShareButton>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(href)
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }, 3000)
                  }}
                  sx={{
                    padding: 0,
                    width: 'auto',
                    height: 'auto',
                    cursor: 'pointer'
                  }}
                >
                  <Link />
                </IconButton>
              </Flex>
            </Flex>
            {image && (
              <Box
                sx={{
                  width: ['100%', '48%'],
                  '.gatsby-image-wrapper > div': { pb: '100% !important' }
                }}
              >
                {video && (
                  <video
                    src={video.file.url}
                    controls
                    autoPlay
                    muted
                    sx={{ width: '100%', objectFit: 'contain' }}
                  />
                )}
                {!video && (
                  <ImgLoader
                    image={image}
                    imgStyle={{ objectFit: 'cover' }}
                    forwardSx={{
                      maxWidth: [null, '518px'],
                      borderRadius: '8px'
                    }}
                  />
                )}
              </Box>
            )}
          </Flex>
        </Box>
        <GenericSectionDivider fill={rawColors.background} />
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          width: ['100%', '60%'],
          maxWidth: '1024px',
          m: '0 auto',
          mt: ['-100px', 0],
          p: ['0px 30px', 0],
          color: rawColors.background
        }}
      >
        {content &&
          content.map(section => {
            switch (section.__typename) {
              case 'ContentfulTextBlock':
                return (
                  <TextBlock
                    key={section.id}
                    heading={section.heading}
                    body={section.body}
                  />
                )
              case 'ContentfulQuote':
                return (
                  <Fragment key={section.id}>
                    <Spacer amount={['40px', '75px']} />
                    <Heading
                      sx={{
                        fontSize: ['32px', 6],
                        textAlign: 'center',
                        maxWidth: '890px',
                        m: '0 auto',
                        p: { m: 0 }
                      }}
                      dangerouslySetInnerHTML={{
                        __html: section.body?.childMarkdownRemark?.html
                      }}
                    />
                    <Spacer amount={['20px', '37.5px']} />
                  </Fragment>
                )
              case 'ContentfulImageWithSubtitle':
                return (
                  <Fragment key={section.id}>
                    <Spacer amount={['20px', '37.5px']} />
                    <ImageWithSubtitle
                      image={section.image}
                      subtitle={section.subtitle}
                    />
                  </Fragment>
                )

              default:
                break
            }
          })}
        <Spacer amount={['60px', '120px']} />
        <FooterNewsletterSignup
          heading="SUBSCRIBE FOR MORE"
          btnText="Subscribe"
          sx={{
            width: ['100%', 'auto'],
            m: '0 auto',
            pb: 0,
            border: 'none',
            '& *': {
              m: 0
            },

            '& > div, form': {
              gap: '30px',
              width: ['100%', 'auto'],
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            },
            form: {
              width: ['100%', '400px'],
              '& > div': { width: '100%' },
              label: { display: 'none' },
              input: {
                backgroundColor: 'white',
                border: '2.5px solid',
                p: [
                  '20px 0 17px 19px !important',
                  '20px 0 17px 25px !important'
                ],
                borderRadius: '10px',
                fontWeight: 'body',
                letterSpacing: 0,
                '::placeholder': {
                  textTransform: 'lowercase',
                  opacity: 1,
                  letterSpacing: 0
                }
              },
              button: {
                width: '200px',
                height: '50px',
                padding: 0,
                color: '#FFDD00',
                fontSize: 3,
                fontWeight: 'bold',
                ':hover': {
                  backgroundColor: rawColors.background
                }
              }
            },
            h2: {
              fontSize: ['32px', 5]
            },
            span: {
              fontSize: 3
            }
          }}
        />
        <Spacer amount={['40px', '120px']} />
      </Flex>
      <RelatedArticles article={article} />
    </Container>
  )
}

ArticlePage.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string.isRequired,
    content: PropTypes.array,
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    authorName: PropTypes.string
  })
}

export default ArticlePage
