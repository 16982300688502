/**@jsx jsx */
import { Flex, Heading, Box, jsx } from 'theme-ui'
import RichTextRenderer from '~/components/Content/RichText'

const TextBlock = ({ heading, body, ...props }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mt: ['40px', '60px'],
        'h1, h2, h3, h4': { fontWeight: 'heading', m: 0, mb: 4 }
      }}
      {...props}
    >
      {heading && <Heading sx={{ fontSize: ['32px', 5] }}>{heading}</Heading>}
      {body && (
        <Box
          sx={{
            fontSize: ['18px', 3],
            p: { mt: 0, mb: 4, ':last-child': { mb: 0 } },
            lineHeight: '120%',
            h2: { fontSize: 4 },
            'li p': { m: 0, lineHeight: '144%' },
            a: { textDecoration: 'underline' }
          }}
        >
          <RichTextRenderer sx={{ fontSize: 3 }} richText={body} />
        </Box>
      )}
    </Flex>
  )
}

export default TextBlock
