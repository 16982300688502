/**@jsx jsx */
import { Flex, Text, jsx } from 'theme-ui'
import Img from 'gatsby-image'

const ImageWithSubtitle = ({ image, subtitle }) => {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      {image && image.fluid && (
        <Img
          fluid={image.fluid}
          sx={{
            width: ['100%', '52%'],
            border: '2px solid',
            borderRadius: '8px'
          }}
        />
      )}
      {subtitle && (
        <Text
          as="sub"
          sx={{
            textAlign: 'center',
            p: { m: 0 },
            mt: ['15px', '27px'],
            fontSize: [1, 2]
          }}
          dangerouslySetInnerHTML={{
            __html: subtitle.childMarkdownRemark.html
          }}
        />
      )}
    </Flex>
  )
}

export default ImageWithSubtitle
